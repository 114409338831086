import { call, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

import { USER } from '../actions/actionTypes';
import {
  setUser,
  getUserError,
  loginError,
  registerError,
  logoutSuccess,
  logoutError,
  requestPasswordResetError,
  requestPasswordResetSuccess,
  resetPasswordSuccess,
  resetPasswordError,
  checkRegistrationSuccess,
  checkRegistrationError,
} from '../actions/user';
import { userApi } from '../api/user';

function* getUser() {
  try {
    const user = yield call(userApi.getUser);
    yield put(setUser(user));
  } catch (e) {
    yield put(getUserError(e.message));
  }
}

function* checkRegistration({ payload: { token } }) {
  try {
    const { email, hasAccount } = yield call(userApi.checkRegistration, {
      token,
    });
    yield put(
      checkRegistrationSuccess({ email: _.toLower(email), hasAccount })
    );
  } catch (e) {
    yield put(checkRegistrationError(e));
  }
}

function* login({ payload: { email, password } }) {
  try {
    const user = yield call(userApi.login, {
      email: _.toLower(email),
      password,
    });
    yield put(setUser(user));
  } catch (e) {
    yield put(loginError(e));
  }
}

function* register({
  payload: { email, password, doesAgreePrivacy, doesAgreeCookies, token },
}) {
  try {
    const user = yield call(userApi.register, {
      email: _.toLower(email),
      password,
      doesAgreePrivacy,
      doesAgreeCookies,
      token,
    });
    yield put(setUser(user));
  } catch (e) {
    yield put(registerError(e.message));
  }
}

function* logout() {
  try {
    yield call(userApi.logout);
    yield put(logoutSuccess());
  } catch (e) {
    yield put(logoutError(e.message));
  }
}

function* requestPasswordReset({ payload: { email } }) {
  try {
    yield call(userApi.requestPasswordReset, { email: _.toLower(email) });
    yield put(requestPasswordResetSuccess());
  } catch (e) {
    yield put(requestPasswordResetError(e.message));
  }
}

function* resetPassword({ payload: { token, password } }) {
  try {
    yield call(userApi.resetPassword, { token, password });
    yield put(resetPasswordSuccess());
  } catch (e) {
    yield put(resetPasswordError(e.message));
  }
}

function* impersonate({ payload: { token } }) {
  try {
    const user = yield call(userApi.impersonate, { token });
    yield put(setUser(user));
  } catch (e) {
    yield put(loginError(e));
  }
}

const userSagas = [
  takeEvery(USER.GET.REQUEST, getUser),
  takeEvery(USER.CHECK_REGISTRATION.REQUEST, checkRegistration),
  takeEvery(USER.LOGIN.REQUEST, login),
  takeEvery(USER.REGISTER.REQUEST, register),
  takeEvery(USER.LOGOUT.REQUEST, logout),
  takeEvery(USER.PASSWORD_RESET_REQUEST.REQUEST, requestPasswordReset),
  takeEvery(USER.PASSWORD_RESET.REQUEST, resetPassword),
  takeEvery(USER.IMPERSONATE.REQUEST, impersonate),
];

export default userSagas;
