import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Loader } from 'shared/components';
import RequestPasswordReset from 'User/components/PasswordReset/RequestPasswordReset';
import ResetPassword from 'User/components/PasswordReset/ResetPassword';
import Impersonate from 'User/components/Impersonate/Impersonate';

import ProtectedRoute from './ProtectedRoute';

const Main = lazy(() => import('../Main/Main'));
const Login = lazy(() => import('../User/components/Login/Login'));
const Register = lazy(() => import('../User/components/Register/Register'));

const Routes = () => (
  <Suspense fallback={<Loader size='large' />}>
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/register' component={Register} />
      <Route
        exact
        path='/requestPasswordReset'
        component={RequestPasswordReset}
      />
      <Route path='/passwordReset' component={ResetPassword} />
      <Route path='/impersonate/' component={Impersonate} />
      <ProtectedRoute path='/' component={Main} />
    </Switch>
  </Suspense>
);

export default Routes;
