import _ from 'lodash';

import { USER } from '../actions/actionTypes';

const initialState = {
  checkRegistration: {
    email: null,
    isChecking: false,
    hasAccount: null,
    error: null,
  },
  currentUser: {
    _id: null,
    salutation: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    isLoading: true,
    error: null,
  },
  login: {
    isLoading: false,
    error: null,
    errorCode: null,
  },
  register: {
    isLoading: false,
    error: null,
  },
  logout: {
    isLoggedOut: false,
    isLoading: false,
    error: null,
  },
  requestPasswordReset: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  resetPassword: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  isImpersonating: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER.GET.REQUEST:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: true,
        },
      };
    case USER.GET.SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          _id: _.get(action, ['payload', 'user', '_id']),
          salutation: _.get(action, ['payload', 'user', 'salutation']),
          firstName: _.get(action, ['payload', 'user', 'firstName']),
          lastName: _.get(action, ['payload', 'user', 'lastName']),
          phoneNumber: _.get(action, ['payload', 'user', 'phoneNumber']),
          email: _.get(action, ['payload', 'user', 'clientPortal', 'email']),
          isLoading: false,
          error: null,
        },
        login: initialState.login,
        register: initialState.register,
        isImpersonating: _.get(action, ['payload', 'user', 'isImpersonating']),
      };
    case USER.GET.ERROR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case USER.LOGIN.REQUEST:
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: true,
          error: null,
          errorCode: null,
        },
      };
    case USER.LOGIN.ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          error: _.get(action, ['payload', 'error', 'message']),
          errorCode: _.get(action, [
            'payload',
            'error',
            'cause',
            'response',
            'status',
          ]),
        },
      };
    case USER.REGISTER.REQUEST:
      return {
        ...state,
        register: {
          ...state.register,
          isLoading: true,
          error: null,
        },
      };
    case USER.REGISTER.ERROR:
      return {
        ...state,
        register: {
          ...state.register,
          isLoading: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case USER.LOGOUT.REQUEST:
      return {
        ...state,
        logout: {
          ...state.logout,
          isLoading: true,
          error: null,
        },
      };
    case USER.LOGOUT.SUCCESS:
      return {
        ...state,
        logout: {
          ...state.logout,
          isLoggedOut: true,
        },
      };
    case USER.LOGOUT.ERROR:
      return {
        ...state,
        logout: {
          ...state.logout,
          isLoading: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case USER.PASSWORD_RESET_REQUEST.REQUEST:
      return {
        ...state,
        requestPasswordReset: {
          isLoading: true,
          error: null,
          isSuccess: false,
        },
      };
    case USER.PASSWORD_RESET_REQUEST.SUCCESS:
      return {
        ...state,
        requestPasswordReset: {
          isSuccess: true,
          isLoading: false,
          error: null,
        },
      };
    case USER.PASSWORD_RESET_REQUEST.ERROR:
      return {
        ...state,
        requestPasswordReset: {
          isLoading: false,
          isSuccess: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case USER.PASSWORD_RESET.REQUEST:
      return {
        ...state,
        resetPassword: {
          isLoading: true,
          isSuccess: false,
          error: null,
        },
      };
    case USER.PASSWORD_RESET.SUCCESS:
      return {
        ...state,
        resetPassword: {
          isSuccess: true,
          isLoading: false,
          error: null,
        },
      };
    case USER.PASSWORD_RESET.ERROR:
      return {
        ...state,
        resetPassword: {
          isLoading: false,
          isSuccess: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case USER.CHECK_REGISTRATION.REQUEST:
      return {
        ...state,
        checkRegistration: {
          ...state.checkRegistration,
          isChecking: true,
          email: _.get(action, ['payload', 'email']),
        },
      };
    case USER.CHECK_REGISTRATION.SUCCESS:
      return {
        ...state,
        checkRegistration: {
          ...state.checkRegistration,
          email: _.get(action, ['payload', 'email']),
          isChecking: false,
          hasAccount: _.get(action, ['payload', 'hasAccount']),
        },
      };
    case USER.CHECK_REGISTRATION.ERROR:
      return {
        ...state,
        checkRegistration: {
          email: null,
          isChecking: false,
          hasAccount: false,
          error: _.get(action, ['payload', 'error']),
        },
      };
    case USER.CHECK_REGISTRATION.CLEAR:
      return {
        ...state,
        checkRegistration: {
          email: null,
          isChecking: false,
          hasAccount: null,
          error: null,
        },
      };
    case USER.CLEAR:
      return {
        ...state,
        currentUser: initialState.currentUser,
      };
    case USER.IMPERSONATE.SUCCESS:
      return {
        ...state,
        isImpersonating: true,
      };
    default:
      return state;
  }
}
