import { GET, POST } from 'shared/utils/api';

export const userApi = {
  checkRegistration: ({ token }) =>
    POST('/auth/user/checkRegistration', { token }),
  getUser: () => GET('/auth/user'),
  login: ({ email, password }) => POST('/auth/login', { email, password }),
  register: ({ email, password, doesAgreePrivacy, doesAgreeCookies, token }) =>
    POST('/auth/register', {
      email,
      password,
      doesAgreePrivacy,
      doesAgreeCookies,
      token,
    }),
  logout: () => GET('/auth/logout'),
  requestPasswordReset: ({ email }) =>
    POST('/auth/requestPasswordReset', { email }),
  resetPassword: ({ token, password }) =>
    POST('/auth/resetPassword', { token, password }),
  impersonate: ({ token }) => POST('/auth/impersonate', { token }),
};
