import _ from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectUserId } from 'User/models/selectors/user';
import { Header, Loader } from 'shared/components';
import { impersonateUser } from 'User/models/actions/user';

function Impersonate({ impersonateUser, userId }) {
  const location = new URL(window.location.href);
  const token = location.searchParams.get('token');

  useEffect(() => {
    if (!_.isEmpty(token)) {
      impersonateUser({ token });
    }
  }, [impersonateUser, token]);

  if (userId) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <Header />
      <Loader size='large' />
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: selectUserId(state),
});

const mapDispatchToProps = {
  impersonateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Impersonate);
