import _ from 'lodash';

const selectUserState = (state) => _.get(state, 'user');

const selectCurrentUser = (state) =>
  _.chain(state).thru(selectUserState).get('currentUser').value();

const selectLogout = (state) =>
  _.chain(state).thru(selectUserState).get('logout').value();

export const selectUserId = (state) =>
  _.chain(state).thru(selectCurrentUser).get('_id').value();

export const selectCurrentUserIsLoading = (state) =>
  _.chain(state).thru(selectCurrentUser).get('isLoading').value();

export const selectCurrentUserFirstName = (state) =>
  _.chain(state).thru(selectCurrentUser).get('firstName').value();

export const selectCurrentUserLastName = (state) =>
  _.chain(state).thru(selectCurrentUser).get('lastName').value();

export const selectCurrentUserName = (state) =>
  `${selectCurrentUserFirstName(state)} ${selectCurrentUserLastName(state)}`;

export const selectCurrentUserEmail = (state) =>
  _.chain(state).thru(selectCurrentUser).get('email').value();

export const selectIsLoggedOut = (state) =>
  _.chain(state).thru(selectLogout).get('isLoggedOut').value();

export const selectLoginIsLoading = (state) =>
  _.chain(state).thru(selectUserState).get('login.isLoading').value();

export const selectLoginError = (state) =>
  _.chain(state).thru(selectUserState).get('login.error').value();

export const selectLoginErrorCode = (state) =>
  _.chain(state).thru(selectUserState).get('login.errorCode').value();

const selectRequestPasswordResetState = (state) =>
  _.chain(state).thru(selectUserState).get('requestPasswordReset').value();

export const selectPasswordResetRequestError = (state) =>
  _.chain(state).thru(selectRequestPasswordResetState).get('error').value();

export const selectPasswordResetRequestIsLoading = (state) =>
  _.chain(state).thru(selectRequestPasswordResetState).get('isLoading').value();

export const selectPasswordResetRequestIsSuccess = (state) =>
  _.chain(state).thru(selectRequestPasswordResetState).get('isSuccess').value();

const selectResetPasswordState = (state) =>
  _.chain(state).thru(selectUserState).get('resetPassword').value();

export const selectPasswordResetError = (state) =>
  _.chain(state).thru(selectResetPasswordState).get('error').value();

export const selectPasswordResetIsLoading = (state) =>
  _.chain(state).thru(selectResetPasswordState).get('isLoading').value();

export const selectPasswordResetIsSuccess = (state) =>
  _.chain(state).thru(selectResetPasswordState).get('isSuccess').value();

export const selectRegistrationIsLoading = (state) =>
  _.chain(state).thru(selectUserState).get('register.isLoading').value();

export const selectRegistrationError = (state) =>
  _.chain(state).thru(selectUserState).get('register.error').value();

export const selectCurrentUserPhoneNumber = (state) =>
  _.chain(state).thru(selectCurrentUser).get('phoneNumber').value();

export const selectCheckRegistrationIsChecking = (state) =>
  _.chain(state)
    .thru(selectUserState)
    .get('checkRegistration.isChecking')
    .value();

export const selectCheckRegistrationHasAccount = (state) =>
  _.chain(state)
    .thru(selectUserState)
    .get('checkRegistration.hasAccount')
    .value();

export const selectCheckRegistrationEmail = (state) =>
  _.chain(state).thru(selectUserState).get('checkRegistration.email').value();

export const selectIsImpersonating = (state) =>
  _.chain(state).thru(selectUserState).get('isImpersonating').value();
